export default {
    language: {
        nav: [{
          names: '分散机',
        },{
          names: '砂磨机',
        },{
          names: '储罐',
        },{
          names: '过滤机',
        }],
        product: {
          A: {
            name: '分散机',
            des: '可用于颜料、染料、油漆、油墨等化工行业中',
            data: [
              {
                title: '蝶式搅拌机：三叶蝶式浆叶高效搅拌混合设备',
                url: 'https://sg.chouhuai.com/pc-1926420840',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1926420840-cover.jpg'
              },{
                title: '液压升降乳化机：达到细化均质、分散乳化效果',
                url: 'https://sg.chouhuai.com/pc-170449189',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/170449189-cover.jpg'
              },{
                title: 'TGM-QLF多功能强力分散机：强力分散和搅拌功能的产品',
                url: 'https://sg.chouhuai.com/pc-219577301',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/219577301-cover.jpg'
              },{
                title: '变频调速分散机：可用于混合、搅拌、研磨、分散、乳化等多种工艺操作',
                url: 'https://sg.chouhuai.com/pc-3528058544',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/3528058544-cover.jpg'
              },{
                title: '真空分散机：用于制备高质量的化妆品、涂料、油漆、胶水、食品等产品',
                url: 'https://sg.chouhuai.com/pc-1929725146',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1929725146-cover.jpg'
              },{
                title: '蝶式双轴搅拌机：高粘度涂料真石漆化工产品搅拌混合设备',
                url: 'https://sg.chouhuai.com/pc-1887422178',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1887422178-cover.jpg'
              },{
                title: '电力回转分散机：高效能量存储和平衡电力',
                url: 'https://sg.chouhuai.com/pc-3118974544',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/3118974544-cover.jpg'
              },{
                title: '真空消泡分散剂：高效消泡分散让化学品处理更出色的真空消泡分散剂',
                url: 'https://sg.chouhuai.com/pc-2943197941',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/2943197941-cover.jpg'
              },{
                title: '大型真空分散器适用于液固湿法分散、研磨、混合、均质、混合和溶解化工产品的处理设备',
                url: 'https://sg.chouhuai.com/pc-3552544111',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/3552544111-cover.jpg'
              },{
                title: '高速分散机：化工行业的液体和固体物料的混合、分散和溶解',
                url: 'https://sg.chouhuai.com/pc-4200535880',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/4200535880-cover.jpg'
              }
            ]
          },
          B: {
            name: '砂磨机',
            des: '用于将小颗粒固体研磨成细小的颗粒',
            data: [
              {
                title: '盘式砂磨机：广泛应用于研磨各种化工材料的设备',
                url: 'https://sg.chouhuai.com/pc-1505260525',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1505260525-cover.jpg'
              },{
                title: '立式砂磨机：用于研磨各种材料，如颜料、涂料、油墨、染料、食品、医药等',
                url: 'https://sg.chouhuai.com/pc-2436077533',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/2436077533-cover.jpg'
              },{
                title: '实验室篮式砂磨机：适用于颜料、涂料、油墨、胶黏剂等领域的研究开发',
                url: 'https://sg.chouhuai.com/pc-863510639',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/863510639-cover.jpg'
              },{
                title: '棒销式砂磨机：用于研磨各种化学原料如颜料、涂料、油漆等',
                url: 'https://sg.chouhuai.com/pc-3355117191',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/3355117191-cover.jpg'
              },{
                title: '高效液压三辊研磨机：打造卓越研磨体验',
                url: 'https://sg.chouhuai.com/pc-947623174',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/947623174-cover.jpg'
              },{
                title: '电子数控三辊磨床：高粘度物料最有效的研磨、分散设备',
                url: 'https://sg.chouhuai.com/pc-3677143527',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/3677143527-cover.jpg'
              },{
                title: '卧式砂磨机：非金属矿行业的高效率湿法超微研磨分散机械',
                url: 'https://sg.chouhuai.com/pc-1131295237',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1131295237-cover.jpg'
              },{
                title: '球磨机：研磨金属、非金属、宝石、玻璃等材料的机械设备',
                url: 'https://sg.chouhuai.com/pc-2082559378',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/2082559378-cover.jpg'
              },{
                title: '吊篮式砂磨机是一种集粉碎、分散两道工序于一体的多功能设备',
                url: 'https://sg.chouhuai.com/pc-1041099426',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/1041099426-cover.jpg'
              }
            ]
          },
          C: {
            name: '储罐',
            des: '通常用于工业生产和实验室储存化学品的设备',
            data: [
              {
                title: '不锈钢散装容器：俗称吨装桶、吨桶、吨包装、千升桶',
                url: 'https://sg.chouhuai.com/pc-772052173',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/772052173-cover.jpg'
              },{
                title: '不锈钢双层夹套缸：小批量生产搅拌容器和物料临时存放',
                url: 'https://sg.chouhuai.com/pc-867146315',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/867146315-cover.jpg'
              },{
                title: '高品质不锈钢无菌储罐设备：安全无菌、耐腐蚀',
                url: 'https://sg.chouhuai.com/pc-2575729051',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/2575729051-cover.jpg'
              }
            ]
          },
          D: {
            name: '过滤机',
            des: '一种利用多孔性过滤机实现固液分离的设备',
            data: [
              {
                title: '袋式过滤系统一种新型的过滤系统用于过滤产品的杂质带压力监测表',
                url: 'https://sg.chouhuai.com/pc-951044497',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/951044497-cover.jpg'
              },{
                title: 'DL系列袋式过滤机高效的液体过滤设备',
                url: 'https://sg.chouhuai.com/pc-764316831',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/764316831-cover.jpg'
              },{
                title: '移动袋式过滤器：应用于化工、医药、食品、饮料、电子等行业的生产过程中',
                url: 'https://sg.chouhuai.com/pc-147002530',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/147002530-cover.jpg'
              },{
                title: '高效齿轮泵袋式过滤机：精细过滤、安全可靠、提高生产效率',
                url: 'https://sg.chouhuai.com/pc-878924151',
                cover: 'https://www.chouhuai.com/uploads/product/000/00/00/878924151-cover.jpg'
              }
            ]
          }
        },
        socialize: {
          name: '联系我们',
          des: '...',
          data: [
            {
              icons: 'https://sg.chouhuai.com/static/theme/default/img/universal/menu/e-mail.svg',
              name: 'WhatsApp',
              value: '+8615161608462',
              qr: false
            },{
              icons: 'https://sg.chouhuai.com/static/theme/default/img/universal/social/whatsapp.svg',
              name: 'WhatsApp',
              value: '+8615161608462',
              qr: 'https://www.chouhuai.com/static/theme/default/img/universal/QR-WhatsApp.png'
            },{
              icons: 'https://sg.chouhuai.com/static/theme/default/img/universal/social/skype.svg',
              name: 'Skype',
              value: 'info@jingjiachemindustry.com',
              qr: 'https://sg.chouhuai.com/static/theme/default/img/universal/QR-Skype.png'
            },{
              icons: 'https://sg.chouhuai.com/static/theme/default/img/universal/social/facebook.svg',
              name: 'Facebook',
              value: '@machjingjia',
              qr: false
            },{
              icons: 'https://sg.chouhuai.com/static/theme/default/img/universal/social/linkedin.svg',
              name: 'LinkedIn',
              value: '+info@jingjiachemindustry.com',
              qr: false
            }
          ]
        }
    }
}