<template>
  <div 
  class="body-boxs"
  :class="$i18n.locale">
    <div class="top-boxs">
      <div class="top-stripes">
        <div class="inner-boxs">
          <div @click="language(0)"><img src="./assets/lang/cn.svg" alt="cn">中国 - 中文简体</div>
          <div @click="language(1)"><img src="./assets/lang/us.svg" alt="us">United States - English</div>
        </div>
      </div>
      <div 
      class="top-nav-boxs" 
      :class="{show:menu.status}">
        <div class="inner-boxs">
          <div class="logo">
            <div class="menu" @click="menuEvent()">
              <img :src="menu.icons" alt="">
            </div>
            <div class="name">
              <img src="./assets/images/logo.png" alt="">
            </div>
          </div>
          <nav>
              <a 
              v-for="(items,indexs) in nav"
              :key="indexs" 
              @click="go('GO-N'+(indexs+1))"
              v-text="items.names"
              href="javascript:;"></a>
          </nav>
        </div>
      </div>
    </div>
    <div class="this-top-boxs"></div>
    <div class="body-content function---screen---middle">
      <div class="inner-boxs">
        <div class="socialize">
          <div class="header--text" id="GO-N1">
            <div class="ai-icons">
              <div></div><div></div><div></div><div></div>
            </div>
            <div class="texts">
              <div class="n" v-text="socialize.name"></div>
              <div class="v" v-text="socialize.des"></div>
            </div>
          </div>
          <div class="inner">
            <div class="items" v-for="(items,indexs) in socialize.data" :key="indexs">
              <div class="content">
                <div class="icons">
                  <img :src="items.icons">
                </div>
                <div class="account">
                  <div class="n" v-text="items.name"></div>
                  <div class="v" v-text="items.value"></div>
                </div>
              </div>
              <div class="qr" v-if="items.qr !== false">
                <img :src="items.qr">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="inner-boxs">
        <div class="model--product-list">
            <div class="header--text" id="GO-N1">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <div class="n" v-text="product.A.name"></div>
                <div class="v" v-text="product.A.des"></div>
              </div>
            </div>
            <div class="inner">
              <div class="ui--grid">
                  <div class="tile this--col4">
                    <div class="col" v-for="(items,indexs) in product.A.data" :key="indexs">
                        <div class="items">
                          <div class="cover">
                              <a :href="items.url" target="_blank">
                                <img :src="items.cover" :alt="items.title">
                              </a>
                          </div>
                          <div class="content-boxs">
                              <a 
                              :href="items.url" 
                              v-text="items.title" 
                              target="_blank" 
                              class="title"
                              ></a>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="header--text" id="GO-N2">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <div class="n" v-text="product.B.name"></div>
                <div class="v" v-text="product.B.des"></div>
              </div>
            </div>
            <div class="inner">
              <div class="ui--grid">
                  <div class="tile this--col4">
                    <div class="col" v-for="(items,indexs) in product.B.data" :key="indexs">
                        <div class="items">
                          <div class="cover">
                              <a :href="items.url" target="_blank">
                                <img :src="items.cover" :alt="items.title">
                              </a>
                          </div>
                          <div class="content-boxs">
                              <a 
                              :href="items.url" 
                              v-text="items.title" 
                              target="_blank" 
                              class="title"
                              ></a>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="header--text" id="GO-N3">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <div class="n" v-text="product.C.name"></div>
                <div class="v" v-text="product.C.des"></div>
              </div>
            </div>
            <div class="inner">
              <div class="ui--grid">
                  <div class="tile this--col4">
                    <div class="col" v-for="(items,indexs) in product.C.data" :key="indexs">
                        <div class="items">
                          <div class="cover">
                              <a :href="items.url" target="_blank">
                                <img :src="items.cover" :alt="items.title">
                              </a>
                          </div>
                          <div class="content-boxs">
                              <a 
                              :href="items.url" 
                              v-text="items.title" 
                              target="_blank" 
                              class="title"
                              ></a>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="header--text" id="GO-N4">
              <div class="ai-icons">
                <div></div><div></div><div></div><div></div>
              </div>
              <div class="texts">
                <div class="n" v-text="product.D.name"></div>
                <div class="v" v-text="product.D.des"></div>
              </div>
            </div>
            <div class="inner">
              <div class="ui--grid">
                  <div class="tile this--col4">
                    <div class="col" v-for="(items,indexs) in product.D.data" :key="indexs">
                        <div class="items">
                          <div class="cover">
                              <a :href="items.url" target="_blank">
                                <img :src="items.cover" :alt="items.title">
                              </a>
                          </div>
                          <div class="content-boxs">
                              <a 
                              :href="items.url" 
                              v-text="items.title" 
                              target="_blank" 
                              class="title"
                              ></a>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      site: {
        title: 'PSG Alliance',
        des: 'First inner meeting'  
      },
      menu: {
        status: false,
        icons: require(`@/assets/icons/menu.svg`)
      },
      nav: [],
      product: []
    }
  },
  created () {
    this.nav = this.$i18n.messages[this.$i18n.locale].language.nav;
    this.product = this.$i18n.messages[this.$i18n.locale].language.product;
    this.socialize = this.$i18n.messages[this.$i18n.locale].language.socialize;
  },
  methods: {
    language(type) {
      if (type == 0) {
        localStorage.setItem('language', 'zh')
        this.$i18n.locale = 'zh'
      } else if (type == 1) {
        localStorage.setItem('language', 'en')
        this.$i18n.locale = 'en'
      }
      this.nav = this.$i18n.messages[this.$i18n.locale].language.nav;
      this.product = this.$i18n.messages[this.$i18n.locale].language.product;
      this.socialize = this.$i18n.messages[this.$i18n.locale].language.socialize;
    },
    menuEvent(){
      this.menu.status = this.menu.status === false;

      if (this.menu.status) {
        this.menu.icons = require(`@/assets/icons/hide.svg`);
      }else{
        this.menu.icons = require(`@/assets/icons/menu.svg`);
      }
    },
    go(id){
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      this.menu.status = true;
      this.menuEvent();
    }
  }
}
</script>
